$small: 600px;
$medium: 960px;

body {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  background: rgba(255,255,255,1);
  color: #222d69;
}

.masque {
  -webkit-clip-path: polygon(0 0, 840px 0, 840px 435px, 0% 435px);
  clip-path: polygon(0 0, 840px 0, 840px 435px, 0% 435px);
  width: 100%;
  height: 100%;
  position: fixed;
}

a {
  transition: all 0.2s ease-in;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c0dcfc;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #222d69;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #222d69;
}


.container {
  padding: 0 32px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: top;
  height: 100%;
  flex-direction: column;
}

.scene {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  border: solid 1px;
}


.partie-1 {

	width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .contenu {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

}

.partie-2 {
  width: 100%;
  position: absolute;
  top: 100%;
}

section {
  
  width: 840px;
  //height: 435px;
  justify-content: center;
  flex-direction: column;
  min-height: 435px;
  position: relative;


  &.scroll {
    //width: 840px;
    //height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    //top: 15%!important;
    margin: auto;

    @media screen and (max-width: $small) {
      opacity: 1;
    }

  }


  .contenu {
    //position: fixed;
    position: relative;
    width: 840px;
    height: 435px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: $small) {
      height: inherit;
    }
    
  }

  &.scene-01 {
    opacity: 1;
    //position: absolute;
    display: block;
    //top: 50%;
    //margin-top: calc( 50% + 160px);
    //transform: translateY(-50%);
    //margin-top: 25vh;
    //margin-top: -20vh;
    //height: 100vh!important;
    top: 0!important;

    .el-00  {
      position: absolute;
      background: #fff;
      z-index: 0;
      //position: relative;
      //width: 840px;
      height: 435px;
    }

    #logobp {
      opacity: 0;
    }

    .el-01 {
      opacity: 0;
      width: 80%;
      margin: 12% auto 0;
    }
    
    .el-02{
      opacity: 0;
      width: 28%;
      margin: 4% auto;
      transform: scale(0.8);
    }
    
    .el-03 {
      width: 10%;
      margin: 4% auto 0;
      text-align: center;
      font-weight: bold;
      opacity: 0;
      img {
        margin-top: 0;
        height: 24%;
        opacity: 1;
      }
    }

  }

  &.scene-02 {
    //position: relative;
    //margin-top: 25vh;
    position: absolute;
    //top: 20%!important;
    top: inherit;
    margin-top: 50vh;
    transform: translateY(-50%);

    .el-04{
      //opacity: 0;
      width: 40%;
      margin: 0 auto 0 auto;
    
    }
    .el-05{
      //opacity: 0;
      width: 55%;
      margin: 2% auto 0 auto;
    
    }
    .el-06{
      //opacity: 0;
      width: 50%;
      margin: 2% auto;
    }

  }

  &.scene-03 {
    //opacity: 0;
    position: relative;
    padding-bottom: 10%;
    //margin-top: 25vh;

    .el-07-00 {
      opacity: 1;
      img {
        width: 80%;
         margin: 0 auto 8% auto;
      }
    }

    .el-07 {
      //opacity: 0;
      width: 100%;
      margin: 0% auto;
      transform: scale(1);
      position: relative;
      svg {
        width: 91%;
        position: absolute;
        height: 600px;
      }
    }

  }

  &.scene-04 {

    position: relative;
    margin-top: 0;




    .el-08 {
      //opacity: 0;
      width: 100%;
      margin: 12vw auto 0 auto;
      transform: scale(1);
      position: relative;
      text-align: center;

      @media screen and (min-width: 860px) {
        margin: 18vw auto 0 auto;
      }
  

      @media screen and (min-width: 1200px) {
        margin: 12vw auto 0 auto;
      }

      @media screen and (min-width: 1400px) {
        margin: 8vw auto 0 auto;
      }
      
      @media screen and (min-width: 1500px) {
        margin: 6vw auto 0 auto;
      }
      @media screen and (min-width: 2400px) {
        margin: 2vw auto 0 auto;
      }
      @media screen and (min-width: 2600px) {
        margin: 0vw auto 0 auto;
      }
  

      @media screen and (max-width: $small) {
        //do Smth
        display: none;
      }

      svg {
        width: 75%;
        margin: auto;
        height: auto;
      }
    }

    .ligne {
      display: flex;

      @media screen and (max-width: $small) {
        flex-direction: column;
      }

      .el-09 {
        //opacity: 0;
        transform: scale(0.5);
        img {
          width: 90%;
          margin: auto;

          @media screen and (max-width: $small) {
            width: 100%;
          }

        }        
      }

      .el-10 {
        //opacity: 0;
        transform: scale(0.5);
        margin-top: -5%;
        z-index: 900!important;
      }

      .el-11 {
        //opacity: 0;
        transform: scale(0.5);
        position: relative;

        .pointer-quizz {
          position: absolute;
          z-index: 0;
          width: 50px;
          height: 50px;
          top: 50%;
          //right: -10%;
          right: 60%;

          strong {
            position: absolute;
            top: -20px;
            left: 4px;
          }
          
          img {
            z-index: 1;
            position: relative;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
              transform: scale(1.2);
              transition: all 0.2s ease-in-out;
            }

          }
        }

        img {
          width: 95%;
          height: 96%;
          margin-left: 5%;
        }        
      }
    


      .encart {
        width: calc( 100% / 3);
        z-index: 1000;

        @media screen and (max-width: $small) {
          width: 100%;
          max-width: 320px;
          margin: 1rem auto;
        }

      }
    }





  }

  &.scene-05 {

    //margin-top: 320px;
    
    margin-top: 30%;

    @media screen and (min-width: 860px) {
      margin-top: 35%;
    }

    @media screen and (min-width: 1050px) {
      margin-top: 30%;
    }

    @media screen and (min-width: 1200px) {
      margin-top: 25%;
    }

    @media screen and (min-width: 1400px) {
      margin-top: 18%;
    }


    @media screen and (min-width: 2400px) {
      margin-top: 8%;
    }




    
    .el-12-bas {
      width: 100%;
      //margin: 10vh auto 0 auto;
      transform: scale(1);
      position: relative;
      text-align: center;

      img {
        width: 75%;
        margin: auto;
        height: auto;
      }
    }
    
    .ligne {
      display: block;

      .el-12 {
        position: relative;
        //opacity: 0;

        #piecette1 {
          //transform-origin: -20px 20px!important;
        }

        .adie {
          opacity: 0;
        }

        .lien-adie {
          width: 50px;
          height: 24px;
          left: -50px;
          display: block;
          position: absolute;
        }

        svg {
          width: 100%;
          height: auto;
        }
      }

      .el-13 {
        //opacity: 0;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 24%;
        width: 64%;
        height: 100%;
      }

    }

  }

  &.scene-06 {
    height: inherit;
    margin-top: 190px;
    //margin-top: 21%;
    //margin-top: 14vw;
    //padding-bottom: 100vh;

    .el-14 {
      position: relative;
      padding-left: 6%;

      svg {
        height: auto;
      }


      .pointer-top {
        position: absolute;
        width: 50px;
        height: 50px;
        top: 55%;
        right: 4%;

        .pulse {
          top: 10%;
          right: 10%;
        }
        
        img {
          z-index: 1;
          position: relative;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:hover {
            transform: scale(1.2);
            transition: all 0.2s ease-in-out;
          }

        }
      }

    }

  }

}


.infobulle {
  position: absolute;
  background: url(/assets/picto-infobulle.svg) center center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;

  span {
    transform: scale(0);
    transition: all 0.2s ease-in;
    position: absolute;
    background: #bad25f;
    color: #222d69;
    padding: 20px;
    border-radius: 20px;
    display: block;
    width: 280px;
    left: -140;
    bottom: 40px;
  }

  &:hover {
    cursor: pointer;

    span {
      transform: scale(1);
      transition: all 0.2s ease-in;
    }

  }

  &.epargne {
    top: 9%;
    left: 59%;
    span {

        bottom: inherit;
        top: 40px;
    }
  }

  &.investissements {
    left: 80%;
    bottom: 21%;
  }

  &.depots {
    left: 55%;
    top: 5%;

    span {
      bottom: inherit;
      top: 40px;
    }
  }

  &.fonds {
    left: 66%;
    bottom: 17%;
  }

  &.adie {
    bottom: 23.9%;
    left: 20%;
  }
}

.cadre {
  //opacity: 0;
  //border: 10px solid #74b6e8;
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 0;
  //position: relative;
  width: 840px;
  height: 435px;


  &::after {
    position: absolute;
    
    top:0;
    bottom: 0;
    left:0;
    right:0;
  }
  
  
}

.logo-bp {
  position: absolute;
  width: 90px;
  background: #FFF;
  padding: 20px;
  bottom: -50px;
  right: 20px;
}



.pulse {
  position: absolute;
  top: 1%;
  right: 6%;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(63, 204, 44, 0);
  cursor: pointer;
  box-shadow: 0 0 0 rgba(63, 204, 44, 0.8);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(63, 204, 44, 0.8);
  }
  70% {
      box-shadow: 0 0 0 20px rgba(63, 204, 44, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(63, 204, 44, 0);
  }
}




@media (max-width: 840px)  {

  section {
    width: 100%;
  }

  .contenu, .cadre, .scene-00  {
    width: 100%!important;
  }

  .container {
    padding: 0;
  }


}

.marqueur {
  position: fixed;
  top: 10vh;
  bottom: 10vh;
  background-color: #bad25f;
}

#petitepiece {
  opacity: 0;
  //transform-origin: 0px 30px!important;
}

#pointe {
  opacity: 0;
}



.mobile {
  display: none;
  position: relative;
  width: 100%;

  .espace_bas {
    display: block;
    height: 4%;
  }

  .pulse {
    //margin-top: 2vw;
    //margin-right: 1vw;
    margin-top: 0;
    margin-right: 0;
    top: 14%;
    right: 14%;
    display: block;
    width: 8vw;
    height: 8vw;
  }

  .pointer-top {
    opacity: 1;
    position: absolute;
    bottom: 1.5%;
    left: 28%;
    width: 12%;
  }

  .pointer-quizz {
    opacity: 1;
    position: absolute;
    bottom: 54.5%;
    left: 24%;
    width: 12%;

    strong {
      position: absolute;
      top: -20px;
      left: 4px;
    }
  }

  .infobulle {
    position: absolute;
    bottom: 0;
    top: inherit;

    span {
      left: -200;
    }

    &.epargne {
      bottom: 67.2%;
      left: 78%;
      span {
        left: -220;
        top: inherit;
        bottom: 40px;
      }
    }
    &.investissements {
      bottom: 62.2%;
      left: 76%;
    }
    &.depots {
      bottom: 76.6%;
      left: 77%;

      span {
        bottom: 30px;
        top: inherit;
      }
    }
    &.fonds {
      bottom: 53.4%;
      left: 76%;
    }
    &.adie {
      bottom: 26%;
      left: 77%;
      span {
        left: -200;
      }
    }
    
  }


  .trig {
    position: fixed;
    opacity: 0.5;
    height: 60vh;
    background: #bad25f;
    width: 100%;
    top: 0%;

  }
  
  #_x30_9-encart-1, #_x30_9-encart-2, #_x31_1-encart-3, #_x31_2-credit-01-titre, #_x31_3-credit-02-intro, #_x31_4-credit-03, #_x31_5-credit-04,  #_x31_6-credit-05,  #_x31_7-credit-06, #_x31_8-credit-07, #_x31_9-credit-08, #_x32_0-economie-01 , #_x32_1-economie-03, #_x32_2-economie-04, #_x32_3-economie-05, #_x32_4-economie-06, #_x32_5-economie-07, #_x32_6-economie-08, #_x32_7-retour-top, #_x32_8-piece, #_x32_9-libelle, #_x33_0-piece, .fade  {
    opacity: 0;
  }

  svg {
    width: 90vw!important;
    margin: 100px auto auto auto;
    height: inherit;
    display: flex;
  }
  @media screen and (max-width: $small) {
    display: block;
  }
}

.partie-1, .partie-2 {
  @media screen and (max-width: $small) {
    display: none;
  }
  
.d-none {
  opacity: 0!important;
}

}

.flecheretour {
  opacity: 0;
  transition: all 0.2s ease-in;

  &.actif {
    opacity: 1!important;
  }

}

.fondu {
  animation:  fadein 2s;
}

#as {
  animation:  fadein 1s;
}

#ar {
  animation:  fadein 1.5s;
}

#aq {
  animation:  fadein 2s;
}

#ap {
  animation:  fadein 2.5s;
}

#ao {
  animation:  fadein 3s;
}

#an {
  animation:  fadein 3.5s;
}

#am {
  animation:  fadein 3.5s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


/* HACK SAFARI */
@media not all and (min-resolution:.001dpcm)
{ 
  @supports (-webkit-appearance:none) {

    .mobile svg {
      width: 96vw!important;
    }

    .mobile .pointer-top {
      bottom: 2%;
    }

}
}