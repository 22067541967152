nav {
    background: #f2f2f2;
    position: fixed;
    color: #c0dcfc;
    display: flex;
    margin: auto;
    width: 100%;
    flex-direction: column;
    z-index: 20000;

    ul {
        display: inline-flex;
        justify-content: space-evenly;
        width: 100%;
        max-width: 860px;
        margin: auto;

        @media screen and (max-width: $small) {
            //do Smth
            font-size: 12px;
          }

        a {
            padding: 0.5rem 1rem;
            font-weight: bold;
            cursor: default;
        }
    }

    .on {
        color: #222d69;
    }

    .jauge {
        width: 100%;
        height: 8px;
        background: #c0dcfc;
        border-radius: 4px;
        max-width: 860px;
        //margin: 0 auto 20px;
        margin: 0 auto 0px;
        overflow: hidden;
        @media screen and (max-width: $small) {
            height: 4px;
          }

        span {
            //width: 25%;
            height: 8px;
            background: #222d69;
            display: block;
            @media screen and (max-width: $small) {
                height: 4px;
              }
        }

        &.j50 {
            span {
                width: 50%;
            }
        }

        &.j25 {
            span {
                width: 25%;
            }
        }

        &.j20 {
            span {
                width: 20%;
            }
        }
        &.j75 {
            span {
                width: 75%;
            }
        }
        &.j100 {
            span {
                width: 100%;
            }
        }
    }
}