.modale {
    background: rgba(0,0,0,0.8);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    transition: all 0.2s ease-in;
    z-index: 100000;

    &.off {
        transform: scale(0);
    }

    nav {
        background: transparent;
        position: relative;
        color: #c0dcfc;
        display: flex;
        margin: auto;
        width: 100%;
        flex-direction: column;
        z-index: 10;
        margin-bottom: 20px;

        li {
            width: 100%;
            text-align: center;
            

            &.active {                

                a {
                    color: #222d69;
                    border-bottom: 8px solid #222d69;
                }
            }
        }

        a {
            width: 100%;
            display: block;
            border-bottom: 8px solid #c0dcfc;
        }
    }


    .fenetre {
        background: #e4f0fa;
        width: 640px;
        height: auto;
        margin: auto;
        padding: 20px 80px;
        position: relative;

        @media screen and (max-width: $small) {
            padding: 20px;
            font-size: 14px;
          }

          @media screen and (max-height: 640px) {
            height: 90vh;
            overflow-y: scroll;
          }


        h3 {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 20px;
            @media screen and (max-width: $small) {
                font-size: 14px;
            }
        }
    
        .reponse {
            background: url(/assets/section04/picto-check.png) 98% 50% no-repeat #FFF;
            background-position: 98% 50%;
            background-size: 0px!important;
            border-radius: 10px;
            padding: 20px 46px 20px 20px;
            margin-bottom: 20px;
            transition: all 0.2s ease-in;

            @media screen and (max-width: $small) {
                padding: 10px;
                margin-bottom: 10px;
              }

            .plus {
                display: none;
            }

            &.vert {
                background: url(/assets/section04/picto-check.png) 98% 50% no-repeat #bad25f!important;
                background-size: 40px!important;

                .plus {
                    display: inline;
                }
                @media screen and (max-width: $small) {
                    padding: 10px 50px 10px 10px;
                }
            }

            &.rouge {
                background: url(/assets/section04/picto-croix.png) 98% 50% no-repeat #9a123f!important;
                background-size: 40px!important;
                color: #FFF!important;
            }

            &:hover {
                background: #d0e4ef;
                cursor: pointer;
            }
        }
    
        .close {
            width: 25px;
            height: 25px;
            position: absolute;
            right: 20px;
            top: 20px;
            transition: all 0.2s ease-in;

            @media screen and (max-width: $small) {
                width: 20px;
                height: 20px;
                right: 14px;
                top: 14px;
              }

            &:hover {
                cursor: pointer;
                transition: all 0.2s ease-in;
                transform: rotate(180deg);
            }
        }

    }


	.tab-content {
		float: left;
		width: 100%;

		> .tab-pane {
			display: none;

			&.active {
				display: block;
			}
		}

		> .active {
			display: block;
		}
	}

    .bt_nav {

        width: 80px;
        margin: auto;
        display: flex;
        justify-content: space-between;

        span {
            width: 30px;
            height: 30px;
            background: #cee2ef;
            border-radius: 50%;
            display: flex;
            transition: all 0.2s ease-in;

            &:hover {
                background: #FFF;
                cursor: pointer;
            }

            &.ok {
                background: #FFF;
            }

            
            &.suivant {
                transform: rotate(180deg);
            }

            img {
                width: 10px;
                margin: auto;
            }
        }
    }

}
