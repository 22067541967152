/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─
/*─                                                                                    FOOTER
/*─
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
/*─────────────────────────────────────────────────────────────────────────────────────────────────*/
.footer {

}
